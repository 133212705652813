import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import modalData from "../../constants/modalData";
import { useDispatch } from "react-redux";
import { changeSupportStatus } from "../redux/actions/supportActions";
import { changeWithdrawalStatus } from "../redux/actions/withdrawalActions";

export default function CommonModal({ show, setShow, type, data , setPaginated}) {
  const handleClose = () => setShow(false);
  const dispatch = useDispatch();
  const { title, body } = modalData[type];

  const handleSubmit = async() => {
    switch (type) {
      case "changeSupportStatus":
        console.log({'data at handlesubmit': data});
        const response = await dispatch(changeSupportStatus(data)).unwrap();
        if (response?.status) {
          setPaginated((prev) =>
            prev.map((item) =>
              item._id === data.id
                ? { ...item, status: data.status } // Update the matching item
                : item // Return the unchanged item
            )
          );
        }
        handleClose();
        break;
      case "changeWithdrawalStatus":
        dispatch(changeWithdrawalStatus(data));
        handleClose();
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
