import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GetUrl } from "../config/GetUrl";


export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: GetUrl.API_URL,
  }),
  endpoints: (builder) => ({
    GetUserList: builder.query({
      query: () => ({
        url: "/users",
        method: "GET",
      }),
    }),
    approvedReferee: builder.mutation({
        query: (id) => ({
          url: `/aprroved-referee/${id}`,
          method: "PUT",
        }),
      }),
      EditUser: builder.mutation({
        query: ({ id, data }) => ({
          url: `/users/${id}/update`,
          method: "POST",
          body: data,
        }),
      }),
      getUserDetail: builder.query({
        query: (id) => ({
          url: `/users/${id}`,
          method: "GET",
        }),
      }),
      DeleteUser: builder.mutation({
        query: (id) => ({
          url: `/delete-user/${id}`,
          method: "PATCH",
        }),
      }),
  }),
});

export const {
  useGetUserListQuery,
  useApprovedRefereeMutation,
  useEditUserMutation,
  useGetUserDetailQuery,
  useDeleteUserMutation
} = userApi;
